var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v("Pohon keluarga | Familie stamboom")]),_c('b-form-radio-group',{attrs:{"id":"radio-group","options":_vm.options,"name":"radio-options"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.selected == 'marriage')?_c('b-button',{class:{
      green: this.aPair.filter(a => a.label == '?').length == 0,
      red: this.aPair.filter(a => a.label == '?').length == 1
    },on:{"click":_vm.addCoupleEdge}},[_vm._v(_vm._s(_vm.lbl1)+" heeft een relatie met "+_vm._s(_vm.lbl2))]):_vm._e(),(_vm.selected == 'motherchild')?_c('b-button',{class:{
      green: this.aPair.filter(a => a.label == '?').length == 0,
      red: this.aPair.filter(a => a.label == '?').length == 1
    },on:{"click":_vm.addMotherChildEdge}},[_vm._v(_vm._s(_vm.lbl1)+" is moeder van "+_vm._s(_vm.lbl2))]):_vm._e(),_c('network',{ref:"network",staticClass:"network",attrs:{"nodes":_vm.network.nodes,"edges":_vm.network.edges,"options":_vm.network.options},on:{"select-node":_vm.selectThisNode}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }